<template>
  <div v-if="controls.length">
    <ControlEventSelector :control="control" @addEvent="onAddEvent" />
    <ControlEventForm
      v-for="(item, ix) in eventNameList"
      :key="ix"
      :control="control"
      :eventName="item"
      @delEvent="onDelEvent"
    />
  </div>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import ControlEventForm from "@/components/editor/control-event-form.vue";
import ControlEventSelector from "@/components/editor/control-event-selector.vue";

export default {
  name: "ControlEventEditor",
  components: {
    ControlEventSelector,
    ControlEventForm
  },
  props: {
    controls: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    control() {
      return this.controls.length ? this.controls[0] : null;
    },
    eventNameList() {
      return Object.keys(this?.control?.synopticComponent?.on || {});
    }
  },
  methods: {
    onAddEvent(name) {
      let control = this.control; // not a synoptic control
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      this.$set(
        control.synopticComponent,
        "on",
        control.synopticComponent.on || {}
      );
      this.$set(
        control.synopticComponent.on,
        name,
        JSON.parse(JSON.stringify(Events[name]))
      );
      if (synopticControl) {
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      }
    },
    onDelEvent(name) {
      let control = this.control; // not a synoptic control
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      this.$delete(control.synopticComponent.on, name);
      if (synopticControl) {
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      }
    }
  }
};
</script>

<style scoped></style>
