<template>
  <div
    class="status-label"
    :style="style"
    v-if="currentState || mode == 'editor'"
  >
    <span>
      {{ (currentState && currentState.label) || "TextList" }}
    </span>
  </div>
</template>

<script>
import SynopticEquipmentDataControlBase from "@/components/synoptic/synoptic-equipment-data-control-base.vue";

export default {
  name: "SynopticStatusLabel",
  extends: SynopticEquipmentDataControlBase,
  computed: {
    isDirty() {
      return false;
    },
    currentState: function() {
      var self = this;
      let value =
        typeof this.rawValue == "boolean"
          ? this.rawValue
            ? 1
            : 0
          : this.rawValue;
      var defState = (self.items || []).find(
        (i) => String(i.state).toLowerCase() == "default"
      );
      let state =
        self.items.find(
          (i) => String(i.state) !== "" && String(i.state) === String(value)
        ) ||
        defState ||
        null;
      return state || null;
    },
    items() {
      if (
        !("dataSource" in this.control.synopticComponent) ||
        this.control.synopticComponent?.dataSource?.type == "local"
      ) {
        return this.control?.synopticComponent?.stateImages || [];
      } else {
        let lst = [];
        let id = this.control.synopticComponent?.dataSource?.id || 0;
        if (id) {
          let entry = this.globalLists.find((i) => i.id == id) || null;
          for (let key in entry?.items || {}) {
            let value = key in (entry.default_item || {}) ? "default" : key;
            lst.push({
              state: value,
              backgroundColor: "transparent",
              img: "",
              label: entry.items[key]
            });
          }
        }
        return lst;
      }
    },
    globalLists() {
      return this.$root.config.references.text_lists || [];
    },
    style() {
      return {
        ...this.controlStyle,
        width: this.currentRect.width + "px",
        height: this.currentRect.height + "px",
        "background-color":
          !this.currentState ||
            this.currentState?.backgroundColor == "transparent"
            ? this.controlStyle["background-color"]
            : this.currentState.backgroundColor
      };
    }
  },
  watch: {
    currentState: {
      handler(n) {
        this.$emit("hasContent", n !== null);
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.status-label {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.status-label span {
  width: 100%;
}
</style>
