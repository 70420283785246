<template>
  <vue-context class="has-icon" v-slot="{ data: control }" ref="menu">
    <div class="close-button">
      <i class="fa fa-close clicable"></i>
    </div>
    <li>
      <a class="has-shortcut" @click="undo" data-testid="undo">
        <i class="fa fa-undo"></i>

        {{ $t("undo") }}
        <span class="shortcut">Ctrl+Z</span>
      </a>
    </li>
    <li>
      <a class="has-shortcut" @click="redo" data-testid="redo">
        <i class="fa fa-repeat"></i>

        {{ $t("redo") }}
        <span class="shortcut">Shift+Ctrl+Z</span>
      </a>
    </li>
    <hr />
    <li>
      <a
        :class="['has-shortcut', { disabled: !hasSelected() }]"
        @click="cut"
        data-testid="cut"
        :title="$t('select_control_to_execute_action')"
      >
        <i class="fa fa-cut"></i>
        {{ $tc("cut") }}
        <span class="shortcut"> Ctrl+X </span>
      </a>
    </li>
    <li>
      <a
        :class="['has-shortcut', { disabled: !hasSelected() }]"
        @click="copy"
        data-testid="copy"
        :title="$t('select_control_to_execute_action')"
      >
        <i class="fa fa-copy"></i>
        {{ $tc("copy") }}
        <span class="shortcut"> Ctrl+C </span></a
      >
    </li>
    <li>
      <a class="has-shortcut" @click="paste" data-testid="paste">
        <i class="fa fa-paste"></i>
        {{ $t("paste") }}
        <span class="shortcut"> Ctrl+V </span></a
      >
    </li>
    <li v-if="hasSelected()">
      <a class="has-shortcut" @click="remove" data-testid="remove">
        <i class="fa fa-trash"></i>
        {{ $t("remove") }}
        <span class="shortcut"> DEL </span>
      </a>
    </li>
    <li
      class="v-context__sub"
      @mouseenter="openSubMenu"
      @mouseleave="closeSubMenu"
      data-testid="centralize-menu"
      v-if="hasSelected(1)"
    >
      <hr />
      <a @click="centralize()" data-testid="centralize">
        <img class="icon" src="/static/common/images/align-window-center.svg" />
        {{ $t("centralize") }}</a
      >
      <ul class="v-context has-icon delayed" data-testid="submenu">
        <li>
          <a
            @click="centralizeHorizontally()"
            data-testid="centralizeHorizontally"
          >
            <img
              class="icon"
              src="/static/common/images/align-window-horizontal-center.svg"
            />
            {{ $t("centralize_horizontally") }}</a
          >
        </li>
        <li>
          <a @click="centralizeVertically()" data-testid="centralizeVertically">
            <img
              class="icon"
              src="/static/common/images/align-window-vertical-center.svg"
            />
            {{ $t("centralize_vertically") }}</a
          >
        </li>
      </ul>
    </li>
    <template v-if="hasSelected(2)">
      <li class="v-context__sub">
        <a data-testid="alignment">{{ $t("alignment") }}</a>
        <ul :class="['v-context', { 'has-icon': hasSelected(2) }]">
          <li>
            <a @click="alignBottom(control)" data-testid="alignBottom">
              <i class="glyphicon glyphicon-object-align-bottom"></i>
              {{ $t("align_bottom") }}</a
            >
          </li>
          <li>
            <a @click="alignTop(control)" data-testid="alignTop">
              <i class="glyphicon glyphicon-object-align-top"></i>
              {{ $t("align_top") }}</a
            >
          </li>
          <li>
            <a @click="alignRight(control)" data-testid="alignRight">
              <i class="glyphicon glyphicon-object-align-right"></i>
              {{ $t("align_right") }}</a
            >
          </li>
          <li>
            <a @click="alignLeft(control)" data-testid="alignLeft">
              <i class="glyphicon glyphicon-object-align-left"></i>
              {{ $t("align_left") }}</a
            >
          </li>
          <li>
            <a
              @click="alignHorizontally(control)"
              data-testid="alignHorizontally"
            >
              <i class="glyphicon glyphicon-object-align-horizontal"></i>
              {{ $t("align_horizontally") }}</a
            >
          </li>
          <li>
            <a @click="alignVertically(control)" data-testid="alignVertically">
              <i class="glyphicon glyphicon-object-align-vertical"></i>
              {{ $t("align_vertically") }}</a
            >
          </li>
        </ul>
      </li>
      <li class="v-context__sub" v-if="hasSelected(3)">
        <a data-testid="distribution">{{ $t("distribution") }}</a>
        <ul class="v-context">
          <li class="v-context__sub">
            <a>{{ $t("horizontal") }}</a>
            <ul class="v-context has-icon">
              <li>
                <a
                  @click="distributeHorizontalLeft"
                  data-testid="distributeHorizontalLeft"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-horizontal-left.svg"
                    alt="Distribute horizontal left"
                  />
                  {{ $t("distribute_horizontal_left") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeHorizontalCenter"
                  data-testid="distributeHorizontalCenter"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-horizontal-center.svg"
                    alt="Distribute horizontal center"
                  />
                  {{ $t("distribute_horizontal_center") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeHorizontalRight"
                  data-testid="distributeHorizontalRight"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-horizontal-right.svg"
                    alt="Distribute horizontal right"
                  />
                  {{ $t("distribute_horizontal_right") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeHorizontalGap"
                  data-testid="distributeHorizontalGap"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-horizontal-gap.svg"
                    alt="Distribute horizontal gap"
                  />
                  {{ $t("distribute_horizontal_gap") }}</a
                >
              </li>
            </ul>
          </li>
          <li class="v-context__sub">
            <a>{{ $t("vertical") }}</a>
            <ul class="v-context has-icon">
              <li>
                <a
                  @click="distributeVerticalTop"
                  data-testid="distributeVerticalTop"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-vertical-top.svg"
                    alt="Distribute vertical top"
                  />
                  {{ $t("distribute_vertical_top") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeVerticalCenter"
                  data-testid="distributeVerticalCenter"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-vertical-center.svg"
                    alt="Distribute vertical center"
                  />
                  {{ $t("distribute_vertical_center") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeVerticalBottom"
                  data-testid="distributeVerticalBottom"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-vertical-bottom.svg"
                    alt="Distribute vertical bottom"
                  />
                  {{ $t("distribute_vertical_bottom") }}</a
                >
              </li>
              <li>
                <a
                  @click="distributeVerticalGap"
                  data-testid="distributeVerticalGap"
                >
                  <img
                    class="icon"
                    src="/static/common/images/distribute-vertical-gap.svg"
                    alt="Distribute vertical gap"
                  />
                  {{ $t("distribute_vertical_gap") }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="v-context__sub">
        <a data-testid="format">{{ $t("format") }}</a>
        <ul class="v-context has-icon">
          <li>
            <a @click="sameHeight(control)" data-testid="sameHeight">
              <img
                class="icon"
                src="/static/common/images/same-height.svg"
                alt="Same height"
              />
              {{ $t("same_height") }}</a
            >
          </li>
          <li>
            <a @click="sameWidth(control)" data-testid="sameWidth">
              <img
                class="icon"
                src="/static/common/images/same-width.svg"
                alt="Same width"
              />
              {{ $t("same_width") }}</a
            >
          </li>
          <li>
            <a @click="sameSize(control)" data-testid="sameSize">
              <img
                class="icon"
                src="/static/common/images/same-size.svg"
                alt="Same size"
              />
              {{ $t("same_size") }}</a
            >
          </li>
        </ul>
      </li>
    </template>
  </vue-context>
</template>

<script>
import VueContext from "@/plugins/vue-context";

import { mapGetters, mapActions } from "vuex";
import messages from "@/i18n/synoptic-context-menu";

export default {
  name: "ControlContextMenu",
  i18n: {
    messages
  },
  components: {
    VueContext
  },
  props: {
    zoom: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      subMenuOpenDelay: 500
    };
  },
  computed: {
    ...mapGetters("synoptic", ["selectedControls"])
  },
  methods: {
    open(e, data) {
      this.$refs.menu.open(
        {
          clientX: e.clientX / this.zoom,
          clientY: e.clientY / this.zoom
        },
        data
      );
    },
    openSubMenu(e) {
      this._openSubMenuTimeout = setTimeout(() => {
        e.target.lastChild.classList.add("open");
      }, this.subMenuOpenDelay);
    },
    closeSubMenu(e) {
      clearTimeout(this._openSubMenuTimeout);
      e.target.lastChild.classList.remove("open");
    },
    hasSelected(amount = 1) {
      return this.selectedControls.length >= amount;
    },
    cut(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("cut");
    },
    copy(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("copy");
    },
    paste() {
      this.$root.$emit("paste");
    },
    remove(e) {
      if (!this.hasSelected()) {
        e.stopPropagation();
        return;
      }
      this.$root.$emit("delete");
    },
    undo() {
      this.$root.$emit("undo");
    },
    redo() {
      this.$root.$emit("redo");
    },
    ...mapActions("synoptic", [
      "centralize",
      "centralizeHorizontally",
      "centralizeVertically",
      "alignBottom",
      "alignTop",
      "alignRight",
      "alignLeft",
      "alignHorizontally",
      "alignVertically",
      "distributeHorizontalLeft",
      "distributeHorizontalCenter",
      "distributeHorizontalRight",
      "distributeHorizontalGap",
      "distributeVerticalTop",
      "distributeVerticalCenter",
      "distributeVerticalBottom",
      "distributeVerticalGap",
      "sameHeight",
      "sameWidth",
      "sameSize"
    ])
  }
};
</script>

<style lang="scss" scoped>
.v-context {
  padding: 0 0 5px 0;
  border-radius: 0.4rem;
  & > div.close-button {
    background-color: whitesmoke;
    width: 100%;
    height: 15px;
    position: relative;
    border-bottom: 1px solid #ddd;
    margin-bottom: 5px;
    & > i {
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 2px;
      color: #808080;
      font-size: 80%;
    }
  }
  & > li {
    cursor: pointer;

    & > a {
      text-align: left;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
      font-size: 1.3rem;

      &.disabled {
        opacity: 0.6;
        cursor: initial;

        .shortcut {
          color: inherit;
        }

        &:hover,
        &:focus {
          background-color: initial;
        }
      }

      &.has-shortcut {
        position: relative;
        min-width: 16em;

        .shortcut {
          color: #808080;
          position: absolute;
          right: 1.2rem;
        }
      }
    }
  }

  hr {
    border-color: #8080804d;
    margin: 5px 0;
  }

  &.has-icon > li > a {
    padding-left: 3.7rem;

    i,
    .icon {
      width: 1em;
      height: 1em;
      font-size: 1.168em;
      margin-right: 0.8rem;
      margin-left: -2.5rem;
      position: relative;

      &.glyphicon {
        top: 0.16em;
      }

      &.fa {
        top: 0.1em;
      }
    }

    .icon {
      top: -0.05em;
    }
  }
}

.v-context__sub > .v-context.delayed {
  visibility: hidden;

  &.open {
    visibility: visible;
  }
}
</style>
