<template>
  <div v-if="control.synopticComponent.on[eventName].data_list">
    <div v-if="dataIdList.length" style="clear:both;margin: 10px 0 0 0">
      <label class="clicable no-select" @click="collapsed = !collapsed">
        {{ $tc(collapsed ? "variables" : "source", 1) }}
      </label>
      <span
        @click="collapsed = !collapsed"
        v-if="dataIdList.length"
        class="pull-right btn btn-xs btn-default"
        style="background-color:transparent"
      >
        <span v-if="collapsed">
          {{ $t("add") }}
        </span>
        <span v-else :title="$t('close')">
          <i class="fa fa-close"></i>
        </span>
      </span>
    </div>
    <ControlDataSelector
      value=""
      :addon="$tc('data', 1)"
      v-if="!dataIdList.length || !collapsed"
      @input="onAddData"
      :exclude="dataIdList"
      style="margin-bottom:0;"
      :label="collapsed && !dataIdList.length ? 'source' : ''"
    />
    <div v-if="!collapsed && dataIdList.length">
      {{ $tc("variables", 1) }}
    </div>
    <div
      class="form-group form-group-sm form-group-local"
      v-for="(data_id, ix) in dataIdList"
      :key="data_id"
    >
      <div class="input-group">
        <div
          class="input-group-addon var-name  clicable"
          @click.stop.prevent="
            setSimulation(ix, (simulation.enabled = !simulation.enabled))
          "
        >
          <span
            v-if="expressionVisible || dataIdList.length > 1"
            style="font-size:75%;font-weight:600;"
            >$</span
          ><span style="font-weight:600;">{{
            String.fromCharCode(ix + 97)
          }}</span>
        </div>
        <div
          type="text"
          class="form-control clicable form-control-display"
          v-if="!simulation.enabled"
          @click.stop.prevent="setSimulation(ix, true)"
          :title="dataName(data_id) + '=' + getSimulationValue(ix)"
        >
          {{ dataName(data_id) }}
        </div>
        <input
          v-if="simulation.enabled"
          type="text"
          class="form-control text-center simulator-input"
          @input="setSimulationValue(ix, $event.target.value)"
          :value="getSimulationValue(ix)"
          :ref="'inp' + ix"
          :title="$t('test_value')"
        />
        <div
          class="mini-input-info clicable"
          v-if="simulation.enabled"
          @click.stop.prevent="setSimulation(ix, false)"
        >
          {{ dataName(data_id) }}
        </div>
        <div
          v-if="simulation.enabled"
          class="input-group-addon btn"
          @click.stop.prevent="undo(ix)"
          :title="$t('restore')"
        >
          <i class="fa fa-undo" style="font-size: 90%"></i>
        </div>
        <div
          v-else
          class="input-group-addon btn"
          @click.stop.prevent="onRemoveData(data_id)"
          :title="$t('remove')"
        >
          <i class="fa fa-trash text" style="font-size: 90%"></i>
        </div>
      </div>
    </div>
    <!-- condition list -->
    <div v-if="expression_list.length">
      <div style="font-weight:600; margin: 5px 0">
        {{ $tc("trigger_condition", 2) }}
      </div>
      <div
        class="tigger-condition-panel"
        v-for="(item, iExpression) in expression_list"
        :key="iExpression"
      >
        <div class="tigger-condition-panel-header">
          <span
            class="tigger-condition-panel-title clicable no-select"
            @click.stop.prevent="collapse(iExpression)"
          >
            <label
              class="clicable"
              :class="{
                'text-primary': !collapsedItems[iExpression]
              }"
            >
              <template
                v-if="
                  !(iExpression in collapsedItems) ||
                    collapsedItems[iExpression]
                "
              >
                <span class="tigger-condition-panel-title-if">{{
                  $t("if")
                }}</span>
                {{ item.expression }}
              </template>
              <template v-else>
                <span>{{ $t("if") }}</span>
                [{{ item.expression }}]
              </template>
            </label>
          </span>
          <div class="pull-right" style="white-space: nowrap">
            <span
              class="btn btn-xs"
              @click.stop.prevent="removeExpression(iExpression)"
            >
              <i class="fa fa-trash"></i>
            </span>
            <span
              class="btn btn-xs"
              @click.stop.prevent="collapse(iExpression)"
            >
              <i
                class="fa"
                :class="
                  !(iExpression in collapsedItems) ||
                  collapsedItems[iExpression]
                    ? 'fa-caret-square-o-down'
                    : 'fa-caret-square-o-up'
                "
              ></i>
            </span>
          </div>
        </div>
        <div
          class="tigger-condition-panel-content"
          v-if="iExpression in collapsedItems && !collapsedItems[iExpression]"
        >
          <div class="form-group form-group-sm form-group-local">
            <div class="input-group">
              <div class="input-group-addon bg-gray">{{ $t("if") }}</div>
              <input
                type="text"
                class="form-control"
                v-model="item.expression"
                style="z-index:inherit"
              />
            </div>
          </div>
          <div v-if="item.actions.length">
            {{ $tc("triggered_action", item.actions.length > 1 ? 2 : 1) }}:
          </div>
          <div class="action-item">
            <ControlEventActionForm
              style="clear:both;"
              v-for="(action, iAction) in item.actions"
              :key="iAction"
              :control="control"
              :eventName="eventName"
              :action="action"
              :index="iAction"
              :open="iAction === newActionIndex"
              @delAction="onDelAction(iExpression, iAction)"
            />
          </div>
          <ControlActionSelector
            style="margin: 0 5px"
            :control="control"
            :eventName="eventName"
            :unique="true"
            :exclude="actionExpressionList(iExpression)"
            @addAction="onAddAction(iExpression, $event)"
          />
        </div>
      </div>
    </div>

    <!-- logic expression selector and editor -->
    <div>
      <div v-if="dataIdList.length" style="clear:both;margin: 15px 0 0 0">
        <div class="text-center" v-if="expressionCollapsed">
          <span
            class="btn btn-xs"
            :class="hasVisibleCriterias ? 'btn-default' : 'btn-primary'"
            @click.stop.prevent="setExpressionCollapsed(false)"
            >{{ $t("new_trigger_condition") }}</span
          >
        </div>
        <div v-else>
          <label
            class="clicable no-select"
            @click.stop.prevent="expressionCollapsed = !expressionCollapsed"
          >
            {{ $tc("trigger_condition", 2) }}
            <ToolTip :title="$t('hints.expression_for_actions')" />
          </label>
          <span
            @click.stop.prevent="expressionCollapsed = !expressionCollapsed"
            class="pull-right btn btn-xs btn-default"
            style="background-color:transparent"
          >
            <span v-if="expressionCollapsed">
              {{ $t("add") }}
            </span>
            <span v-else :title="$t('close')">
              <i class="fa fa-close"></i>
            </span>
          </span>
        </div>
      </div>
      <div class="expression-field" v-if="!expressionCollapsed">
        <div
          class="form-group form-group-sm form-group-local"
          v-if="!expressionVisible && dataIdList.length == 1"
        >
          <div class="input-group">
            <div class="input-group-addon btn operator-addon">
              <div
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ singleOperator }} <span class="caret"></span>
              </div>
              <ul class="dropdown-menu">
                <li
                  v-for="(item, ix) in conditions"
                  :key="ix"
                  :title="item.name"
                  @click="singleOperator = item.comparison_operator"
                >
                  <a href="#">{{ item.comparison_operator }}</a>
                </li>
              </ul>
            </div>
            <input
              type="text"
              class="form-control text-center no-padding"
              v-model="singleValue"
              ref="inpExpression"
            />

            <div
              class="input-group-addon btn"
              @click.stop.prevent="setExpressionVisible(true)"
              :title="$t('edit_expression')"
            >
              <i class="fa fa-pencil"></i>
            </div>
            <div
              class="input-group-addon btn"
              @click.stop.prevent="addExpression()"
              :disabled="!isValid"
              :title="$t('add_expression')"
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
        <div
          class="form-group form-group-sm  form-group-local"
          v-if="expressionVisible || dataIdList.length > 1"
        >
          <div class="input-group">
            <input
              type="text"
              class="form-control expression-input"
              v-model="expression"
              ref="inpExpression"
              placeholder="$a == -1 || ($b >= 50 && $b <= 70)"
            />
            <div
              v-if="dataIdList.length == 1"
              class="input-group-addon btn "
              @click.stop.prevent="setExpressionVisible(false)"
              :title="$t('edit_expression')"
            >
              <i class="fa fa-pencil text-primary"></i>
            </div>
            <div
              class="input-group-addon btn"
              @click.stop.prevent="addExpression()"
              :disabled="!isValid"
              :title="$t('add_expression')"
            >
              <i class="fa fa-plus"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "ControlEventDataListForm",
  props: {
    control: {
      type: Object,
      required: true
    },
    eventName: {
      type: String,
      required: true
    }
  },
  components: {
    ControlDataSelector,
    ControlActionSelector,
    ControlEventActionForm,
    ToolTip
  },
  data() {
    return {
      collapsed: true,
      expressionVisible: false,
      expressionCollapsed: true,
      expression: "",
      collapsedItems: {},
      simulation: {
        enabled: false,
        values: {},
        previous: {
          style: null
        }
      },
      newActionIndex: -1
    };
  },
  computed: {
    expression_list() {
      return (
        this?.control?.synopticComponent?.on[this.eventName]?.expression_list ||
        []
      );
    },
    singleOperator: {
      set(value) {
        value = value == "=" ? "==" : value;
        let lst = (this.expression || "$a >= 0").split(" ");
        if (lst.length == 3) {
          lst[1] = value;
        } else {
          lst = ["$a", value, "0"];
        }
        //let previous = this.expression;
        this.expression = lst.join(" ");
        // if (
        //   this.expression_list.length == 1 &&
        //   previous == this.expression_list[0].expression &&
        //   !this.expressionVisible
        // ) {
        //   this.expression_list[0].expression = this.expression;
        // } else if (this.expression_list.length == 0) {
        //   this.addExpression();
        // }
        this.$nextTick(() => {
          if (this.$refs.inpExpression) {
            this.$refs.inpExpression.focus();
          }
        });
      },
      get() {
        let lst = (this.expression || "$a >= 0").split(" ");
        let value = lst.length == 3 ? lst[1] : ">=";
        return value == "==" ? "=" : value;
      }
    },
    singleValue: {
      set(value) {
        let lst = (this.expression || "$a >= 0").split(" ");
        if (lst.length == 3) {
          lst[2] = isNaN(parseFloat(value)) ? value : parseFloat(value);
        } else {
          lst = ["$a", ">=", value];
        }
        // let previous = this.expression;
        this.expression = lst.join(" ");
        // if (
        //   this.expression_list.length == 1 &&
        //   previous == this.expression_list[0].expression &&
        //   !this.expressionVisible
        // ) {
        //   this.expression_list[0].expression = this.expression;
        // } else if (this.expression_list.length == 0) {
        //   this.addExpression();
        // }
      },
      get() {
        let lst = (this.expression || "$a >= 0").split(" ");
        return lst.length == 3 ? lst[2] : ">=";
      }
    },
    dataIdList() {
      return (
        this?.control?.synopticComponent?.on[this.eventName]?.data_list || []
      ).map((i) => i.data_id);
    },
    references() {
      return (
        ("config" in this.$root &&
          "references" in this.$root.config &&
          this.$root.config.references) ||
        {}
      );
    },
    conditions() {
      return this.references.alarm_trigger_conditions || [];
    },
    synopticControl() {
      let control = this.control;
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      return control;
    },
    isValid() {
      // TODO: add another set of expression validation
      if (this.expressionVisible && this.expression == "") return false;
      return !(this.expression_list || []).find(
        (item) => item.expression == this.expression
      );
    },
    extendedDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    hasVisibleCriterias() {
      if (this.expression_list.length) {
        for (var ix in this.collapsedItems || {}) {
          if (!this.collapsedItems[ix]) return true;
        }
      }
      return false;
    }
  },
  methods: {
    setExpressionCollapsed(value) {
      this.expressionCollapsed = value;
      if (!value) {
        this.setExpressionVisible(true);
      }
    },
    setExpressionVisible(value) {
      this.expressionVisible = value;
      this.$nextTick(() => {
        if (this.$refs.inpExpression) {
          this.$refs.inpExpression.focus();
        }
      });
    },
    dataName(dataId) {
      let item = this.extendedDataList.find((i) => i.id == dataId);
      let name = (item && item.name) || "";
      if (!name) {
        let connector =
          this.$store.getters["dashboard/dashboardEquipment"] || null;
        if (connector && connector.base_model) {
          let prop = dataId.replace(/connector_/, `connector_${connector.id}_`);
          item = this.extendedDataList.find((i) => i.id == prop);
          name = (item && item.name) || "";
        }
      }
      return name;
    },
    onAddData(dataId) {
      if (!dataId) return;
      let lst =
        this?.control?.synopticComponent?.on[this.eventName]?.data_list || [];
      lst.push({ data_id: dataId });
      this.$set(
        this.control.synopticComponent.on[this.eventName],
        "data_list",
        lst
      );
      this.$set(this.simulation.values, lst.length - 1, "");
      this.expressionVisible = false;
      this.collapsed = true;
      this.expressionCollapsed = true;
      if (lst.length == 1) {
        this.expression = "$a >= 0";
      }
      this.$nextTick(() => {
        if (this.$refs.inpExpression) {
          this.$refs.inpExpression.focus();
        }
      });
    },
    onRemoveData(dataId) {
      let lst =
        this?.control?.synopticComponent?.on[this.eventName]?.data_list || [];
      let index = lst.findIndex((i) => i.data_id == dataId);
      if (index >= 0) {
        lst.splice(index, 1);
        this.$set(
          this.control.synopticComponent.on[this.eventName],
          "data_list",
          lst
        );
        if (!lst.length) {
          this.expression = "";
          this.simulation.enabled = false;
          this.resetExpressionList();
          // this.setExpressionVisible(false);
          this.collapsed = false;
          this.expressionCollapsed = true;
        }
        if (index in this.simulation.values) {
          delete this.simulation.values[index];
        }
      }
    },
    resetExpressionList() {
      this.$set(
        this.control.synopticComponent.on[this.eventName],
        "expression_list",
        []
      );
    },
    removeExpression(ix) {
      let lst =
        this?.control?.synopticComponent?.on[this.eventName]?.expression_list ||
        [];
      if (ix >= 0 && ix < lst.length) {
        lst.splice(ix, 1);
        this.$set(
          this.control.synopticComponent.on[this.eventName],
          "expression_list",
          lst
        );
        delete this.collapsedItems[ix];
      }
      if (!lst.length) {
        this.expressionCollapsed = false;
        this.$nextTick(() => {
          if (this.$refs.inpExpression) {
            this.$refs.inpExpression.focus();
          }
        });
      }
    },
    addExpression() {
      if (!this.expression && !this.expressionVisible) {
        this.singleValue = this.singleValue; // force reactivity
      }
      if (this.expression) {
        if (!this.isValid) return;
        let lst =
          this?.control?.synopticComponent?.on[this.eventName]
            ?.expression_list || [];
        // if (lst.find((item) => item.expression == this.expression)) {
        //   return; // do not allow expression duplication
        // }
        lst.push({
          expression: this.expression,
          actions: []
        });
        this.$set(
          this.control.synopticComponent.on[this.eventName],
          "expression_list",
          lst
        );
        this.$set(this.collapsedItems, lst.length - 1, true);
        this.collapse(lst.length - 1);
        // this.$nextTick(() => {
        //   if (this.$refs.inpExpression) {
        //     this.$refs.inpExpression.focus();
        //   }
        // });
        this.expressionCollapsed = true;
      }
    },
    onAddAction(ix, action) {
      let control = this.synopticControl;
      let evt = control.synopticComponent.on[this.eventName] || null;
      if (evt) {
        let lst = evt?.expression_list || [];
        if (lst.length && ix >= 0 && ix < lst.length) {
          lst[ix].actions = lst[ix].actions || [];
          lst[ix].actions.push(action);
          this.$set(control.synopticComponent.on, this.eventName, evt);
          this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
            id: control.id,
            control: control
          });
          this.newActionIndex = lst[ix].actions.length - 1;
        }
      }
    },
    onDelAction(ix, index) {
      let control = this.synopticControl;
      let evt = control.synopticComponent.on[this.eventName] || null;
      if (evt) {
        let lst = evt?.expression_list || [];
        if (lst.length && ix >= 0 && ix < lst.length) {
          lst[ix].actions = lst[ix].actions || [];
          if (lst[ix].actions.length) {
            lst[ix].actions.splice(index, 1);
            this.$set(control.synopticComponent.on, this.eventName, evt);
            this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
              id: control.id,
              control: control
            });
          }
        }
      }
      this.newActionIndex = -1;
    },
    collapse(ix) {
      this.$set(
        this.collapsedItems,
        ix,
        !(ix in this.collapsedItems ? this.collapsedItems[ix] : true)
      );
    },
    actionExpressionList(ix) {
      let actions = [];
      let control = this.synopticControl;
      if (control) {
        let evt = control.synopticComponent.on[this.eventName] || null;
        if (evt) {
          let lst = evt?.expression_list || [];
          if (lst.length && ix >= 0 && ix < lst.length) {
            actions = lst[ix].actions || [];
          }
        }
      }
      return actions.map((i) => i.type);
    },
    undo(ix) {
      if (this.simulation.enabled && this.getSimulationValue(ix) === "") {
        this.simulation.enabled = false;
      } else {
        this.setSimulationValue(ix, "");
        if (this.$refs[`inp${ix}`]?.length && this.$refs[`inp${ix}`][0].focus) {
          this.$refs[`inp${ix}`][0].focus();
        }
      }
    },
    setSimulationValue(ix, value) {
      let dataId = this.dataIdList[ix] || 0;
      if (dataId) {
        this.simulation.values[ix] = value;
        let connector =
          this.$store.getters["dashboard/dashboardEquipment"] || null;
        if (
          isNaN(dataId) &&
          connector &&
          connector.base_model &&
          dataId.replace(/connector_/, "") != dataId
        ) {
          let properties = {};
          properties[dataId.replace(/connector_/, "")] = value;
          this.$store.dispatch("dashboard/setConnectorPropertyValue", {
            connector_id: connector.id,
            properties: properties
          });
          return;
        }
        if (isNaN(parseFloat(value))) {
          let data = this.$store.getters["dashboard/dataList"].find(
            ({ id }) => parseInt(id) == parseInt(dataId)
          );
          if (data && "restore" in data) {
            this.$store.dispatch("dashboard/setDataValue", {
              id: dataId,
              current_value: data.restore
                ? { ...data.restore }
                : {
                    id: dataId,
                    value: null,
                    date_time: new Date().toISOString()
                  }
            });
          }
        } else {
          this.$store.dispatch("dashboard/setDataValue", {
            id: dataId,
            value: parseFloat(value)
          });
        }
      }
    },
    getSimulationValue(ix) {
      return ix in this.simulation.values ? this.simulation.values[ix] : "";
    },
    setSimulation(ix, enabled) {
      let control = this.synopticControl;
      if (control) {
        this.simulation.enabled = enabled;
        this.restoreStyle();
        if (enabled) {
          let lst = this.$store.getters["dashboard/extendedDataList"] || [];
          if (lst.length) {
            (this.dataIdList || []).forEach((id, ix) => {
              let data = lst.find((item) => item.id == id);
              if (data && data.restore) {
                this.setSimulationValue(ix, data.restore.value); //
              }
            });
          }
          // for (var i in this.simulation.values) {
          //   this.setSimulationValue(i, ""); //
          // }
          this.$nextTick(() => {
            let lst = this.$refs["inp" + ix] || [];
            if (lst.length && "focus" in lst[0]) {
              lst[0].focus();
            }
          });
        } else {
          this.restoreStyle();
        }
      }
    },
    restoreStyle() {
      this.$store.commit("RESTORE_DATA_VALUE", this.dataIdList);
      if (this?.synopticControl?.synopticComponent?.tmp) {
        this.$set(this.synopticControl.synopticComponent, "tmp", null);
        let panel = this.$store.getters["synoptic/panel"];
        if (panel) {
          (panel?.options?.controls || []).forEach((control) => {
            if (control.synopticComponent.tmp) {
              let ctrl = JSON.parse(JSON.stringify(control));
              ctrl.synopticComponent.tmp = null;
              this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
                id: control.id,
                control: ctrl
              });
            }
          });
        }
      }
    }
  },
  beforeDestroy() {
    this.restoreStyle();
  }
};
</script>

<style scoped>
.form-group-local {
  margin-bottom: 0px;
  clear: both;
}

.var-name {
  min-width: 32px;
  padding: 0;
  position: relative;
}

.operator-addon {
  width: 24%;
  padding: 0;
}

.operator-addon .caret {
  float: right;
  margin: 6px 6px 0 0;
}

.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.tigger-condition-panel {
  padding: 5px 2px 2px 2px;
  margin: 0 0 2px 0;
  clear: both;
  background-color: whitesmoke;
  border-bottom: 1px solid lightgray;
}

.tigger-condition-panel-title {
  clear: both;
}

.tigger-condition-panel-title-if {
  padding: 0 5px;
  border-radius: 2px;
  color: #000;
  background-color: #d2d6de;
}

.tigger-condition-panel-header {
}

.tigger-condition-panel-content {
  padding: 0 5px 10px 5px;
}

.action-item {
  margin: 0px 2px 10px 0px;
  padding: 0 5px 8px 5px;

  border-radius: 5px;
}

.expression-input {
  font-size: 80%;
  padding: 0 2px;
  letter-spacing: -0.01em;
}

.mini-input-info {
  position: absolute;
  width: 100%;
  white-space: normal;
  padding: 0 56px 0 2px;
  top: 0;
  z-index: 4;
  font-size: 70%;
  color: #8a8d8e;
  font-weight: 600;
  line-height: 1em;
}

.form-control-display {
  font-size: 80%;
  text-align: center;
  overflow: hidden;
}
.simulator-input {
  padding: 10px 0 0 0;
  width: 100%;
  font-weight: 600;
  color: black;
}

input.simulator-input::-webkit-outer-spin-button,
input.simulator-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input.simulator-input[type="number"] {
  -moz-appearance: textfield;
}
</style>
