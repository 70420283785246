export default {
  pt: {
    alignment: 'Alinhamento',
    centralize: 'Centralizar',
    centralize_horizontally: 'Centralizar horizontalmente',
    centralize_vertically: 'Centralizar verticalmente',
    align_bottom: 'Alinhar à base',
    align_top: 'Alinhar ao topo',
    align_right: 'Alinhar à direita',
    align_left: 'Alinhar à esquerda',
    align_horizontally: 'Alinhar horizontalmente',
    align_vertically: 'Alinhar verticalmente',
    distribution: 'Distribuição',
    distribute_horizontal_left: 'Distribuir lados esquerdos à mesma distância',
    distribute_horizontal_center: 'Distribuir centros à mesma distância',
    distribute_horizontal_right: 'Distribuir lados direitos à mesma distância',
    distribute_horizontal_gap: 'Distribuir distância horizontal igualmente',
    distribute_vertical_top: 'Distribuir topos à mesma distância',
    distribute_vertical_center: 'Distribuir centros à mesma distância',
    distribute_vertical_bottom: 'Distribuir bases à mesma distância',
    distribute_vertical_gap: 'Distribuir distância vertical igualmente',
    same_height: 'Mesma altura',
    same_width: 'Mesma largura',
    same_size: 'Mesmo tamanho'
  },
  en: {
    alignment: 'Alignment',
    centralize: 'Centralize',
    centralize_horizontally: 'Centralize horizontally',
    centralize_vertically: 'Centralize vertically',
    align_bottom: 'Align to bottom',
    align_top: 'Align to top',
    align_right: 'Align to right',
    align_left: 'Align to left',
    align_horizontally: 'Align horizontally',
    align_vertically: 'Align vertically',
    distribution: 'Distribution',
    distribute_horizontal_left: 'Distribute left sides evenly',
    distribute_horizontal_center: 'Distribute horizontal centers evenly',
    distribute_horizontal_right: 'Distribute right sides evenly',
    distribute_horizontal_gap: 'Distribute horizontal distance evenly',
    distribute_vertical_top: 'Distribute top sides evenly',
    distribute_vertical_center: 'Distribute vertical centers evenly',
    distribute_vertical_bottom: 'Distribute bottom sides evenly',
    distribute_vertical_gap: 'Distribute vertical distance evenly',
    same_height: 'Same height',
    same_width: 'Same width',
    same_size: 'Same size'
  }
}