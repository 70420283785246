<template>
  <div>
    <ul class="nav nav-tabs control-sidebar-tabs">
      <li :title="$t('properties')" data-testid="properties-tab">
        <a
          href="#control-sidebar-properties"
          data-toggle="tab"
          aria-expanded="true"
        >
          <i class="fa fa-gear"></i>
        </a>
      </li>
      <li class="active" :title="$t('controls')" data-testid="controls-tab">
        <a
          href="#control-sidebar-controls"
          data-toggle="tab"
          aria-expanded="true"
        >
          <i class="fa fa-cubes"></i>
        </a>
      </li>
      <li :title="$t('events')" v-if="currentWidget" data-testid="events-tab">
        <a
          href="#control-sidebar-events"
          data-toggle="tab"
          aria-expanded="true"
        >
          <i class="fa fa-flash"></i>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane"
        id="control-sidebar-properties"
        data-testid="properties-content"
      >
        <TogglePanel
          title="properties"
          data-testid="toggle-panel"
          :collapsed="false"
          :icon="{ before: 'fa fa-sliders' }"
        >
          <component
            v-if="currentWidget"
            :is="currentWidget"
            :controls="selectedControls"
            data-testid="selected-control-widget"
          ></component>
          <Canvas v-else data-testid="canvas" :controls="selectedControls" />
        </TogglePanel>
      </div>
      <div
        class="tab-pane active"
        id="control-sidebar-controls"
        data-testid="controls-content"
      >
        <TogglePanel
          title="available_controls"
          data-testid="toggle-panel"
          :collapsed="false"
        >
          <ControlGalleryPanel data-testid="gallery-panel" />
        </TogglePanel>
        <TogglePanel
          title="controls"
          data-testid="toggle-panel"
          :collapsed="false"
        >
          <ControlSelectionPanel data-testid="selection-panel" />
        </TogglePanel>
      </div>
      <div
        class="tab-pane"
        id="control-sidebar-events"
        data-testid="events-content"
      >
        <TogglePanel
          title="events"
          v-if="currentWidget"
          data-testid="toggle-panel"
          :collapsed="false"
        >
          <ControlEventEditor
            data-testid="event-editor"
            :controls="selectedControls"
          />
        </TogglePanel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlGalleryPanel from "@/components/synoptic/property-editor/control-gallery-panel.vue";
import ControlSelectionPanel from "@/components/synoptic/property-editor/control-selection-panel.vue";
import ControlEventEditor from "@/components/editor/control-event-editor.vue";
export default {
  name: "SynopticPropertyEditor",
  components: {
    TogglePanel,
    ControlGalleryPanel,
    ControlSelectionPanel,
    ControlEventEditor,
    Canvas: () => import("./controls/canvas"),
    IconizedDisplayValue: () => import("./controls/iconized-display-value"),
    StatusIcon: () => import("./controls/status-icon"),
    StatusLabel: () => import("./controls/status-label"),
    StaticLabel: () => import("./controls/static-label"),
    FormattedDisplayValue: () => import("./controls/formatted-display-value"),
    SynopticImage: () => import("./controls/synoptic-image"), // vue does not allow the usage of "Image" as component
    DataValueInput: () => import("./controls/data-value-input"),
    DataValueSelect: () => import("./controls/data-value-select"),
    DataValueToggle: () => import("./controls/data-value-toggle"),
    DataValueCheckbox: () => import("./controls/data-value-checkbox"),
    SynopticButton: () => import("./controls/synoptic-button"), // vue does not allow the usage of "Button" as component
    ProgressBar: () => import("./controls/progress-bar"),
    Gauge: () => import("./controls/gauge.vue"),
    SimpleTable: () => import("./controls/simple-table"),
    SynopticChart: () => import("./controls/chart")
  },
  computed: {
    currentWidget() {
      let name = this.selectedControls.length
        ? this.selectedControls[0].synopticComponent.componentName
        : "";
      return name
        ? name in this.$options.components
          ? name
          : name.replace("Synoptic", "") in this.$options.components
          ? name.replace("Synoptic", "")
          : null
        : null;
    },
    ...mapState("synoptic", ["selectedControls"])
  },
  methods: {
    showControlProperty() {
      $('.nav-tabs a[href="#control-sidebar-properties"]').tab("show");
      this.$root.$emit("editor.keyboard:focus");
    }
  },
  mounted() {
    this.$store.dispatch("synoptic/clearSelectedControls");
    this.$root.$on("control:selected", this.showControlProperty.bind(this));
  },
  beforeDestroy() {
    this.$root.$off("control:selected", this.showControlProperty.bind(this));
  }
};
</script>

<style scoped>
.nav {
  display: flex;
}

.nav li {
  flex: 1;
}

.nav li a {
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}

.nav li a:hover {
  opacity: 1;
}

.nav li.active a {
  opacity: 1;
}
.property-panel-title {
  padding: 0 0 2px 0;
  line-height: 1em;
  text-align: center;
  color: gray;
  border-bottom: 1px solid lightgray;
}
</style>
