<template>
  <div class="display-box">
    <div class="display-box-value">
      <span class="value" style="">{{ value }}</span>
      <span class="unit" style="">{{ unit }}</span>
    </div>
    <div
      class="display-box-addon"
      v-if="
        control.synopticComponent.children &&
          control.synopticComponent.children.length
      "
      v-bind:class="{ notVisible: !hasContent }"
    >
      <SynopticStatusIcon
        v-bind:control="control.synopticComponent.children[0]"
        v-bind:lastData="lastData"
        v-on:hasContent="onHasContent"
      >
      </SynopticStatusIcon>
    </div>
  </div>
</template>

<script>
import SynopticStatusIcon from "@/components/synoptic/synoptic-status-icon.vue";

export default {
  name: "SynopticIconizedDisplayValue",
  components: {
    SynopticStatusIcon
  },
  data() {
    return {
      hasContent: false
    };
  },
  props: {
    control: Object,
    lastData: Object,
    isReady: Boolean,
    relativeProportions: Object
  },
  methods: {
    onHasContent: function(option) {
      this.hasContent = option;
    }
  },
  computed: {
    value: function() {
      var self = this;
      let fmt = "-";
      if (self.lastData) {
        let vlr =
          self.lastData.current_value &&
          parseFloat(self.lastData.current_value.value);
        fmt = vlr;
        if ("format" in self.control && self.control.format) {
          fmt = self.$utils.sprintf(self.control.format, vlr);
        } else if ("decimals" in self.control) {
          fmt = parseFloat(self.lastData.current_value.value).toFixed(
            self.control.decimals || 0
          );
        }
      }
      return fmt;
    },
    unit: function() {
      return this.lastData && this.lastData.unidade_eng;
    }
  },
  mounted: function() {
    this.$emit("hasContent", true);
  }
};
</script>

<style scoped>
.display-box {
  display: table;
  width: 100%;
  background-color: transparent;
  color: black;
  border: 1px solid #afafaf;
  border-radius: 3px;
  box-shadow: none;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 18pt;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  vertical-align: middle;
}

.display-box .display-box-value {
  display: table-cell;

  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px;
}

.display-box .display-box-addon {
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  max-width: 25%;
  width: 25%;
  padding: 0;
  border-color: transparent;
}

.display-box-addon:first-child {
  border-right: 1px solid #efefef;
}

.display-box-addon:last-child {
  border-left: 1px solid #efefef;
}

.notVisible {
  display: none !important;
}
</style>
