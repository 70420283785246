<template>
  <section ref="me">
    <div class="box" :class="panelBorderClass">
      <div class="box-header">
        <span
          :class="isEditing ? 'clicable-title' : ''"
          @click.stop.prevent="$emit('panelProperties')"
        >
          {{ $t(title) }}
        </span>
        <slot name="toolbar" :actions="toolbarActions"></slot>
      </div>
      <div class="box-body box-body-synotic" ref="synoptic" :style="bodyStyle">
        <SynopticDisplay
          ref="display"
          :equipment="equipment"
          :display="display"
          :mode="mode"
          :panel="panel"
          :isEditing="isEditing"
        />
      </div>
      <Spin v-if="!ready" />
    </div>
  </section>
</template>

<script>
import SynopticDisplay from "@/components/synoptic/synoptic-display.vue";

import SynopticPropertyEditor from "@/components/synoptic/property-editor";
import Spin from "@/components/spin";

export default {
  name: "SynopticPanel",
  components: {
    SynopticDisplay,
    Spin
  },
  data: function() {
    return {
      area: 0,
      refreshTimer: null,
      ready: true,
      referenceIds: null
    };
  },
  props: {
    equipment: { type: Object, required: false, default: () => ({}) },
    panel: { type: Object, required: true },
    display: { type: Object, required: false },
    panelName: { type: String, required: true },
    screenId: { type: [String, Number], required: false, default: () => 0 },
    title: { type: String, required: true },
    isEditing: { type: Boolean, required: false, default: () => false }
  },
  computed: {
    panelBorderClass() {
      if (window.location != window.parent.location) return "embedded";
      return this.isEditing ? "box-warning" : "box-primary";
    },
    panelOptions: function() {
      var panel = this.panel || null;
      return (panel && panel.options) || null;
    },
    dashboardDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    bodyStyle() {
      let panelStyle = this?.panel?.style || {};
      if ("background-color" in panelStyle) {
        return {
          "background-color": panelStyle["background-color"]
        };
      }
      return {};
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"];
    },
    isPanelLocked() {
      return this.$store.getters["synoptic/isPanelLocked"];
    },
    selectToChange() {
      return this.$store.getters["synoptic/selectToChange"];
    },
    toolbarActions() {
      return [
        {
          title: this.$t("undo_action"),
          class: "btn-img btn-undo",
          run: this.onUndo.bind(this)
        },
        {
          title: this.$t("redo_action"),
          class: "btn-img btn-redo",
          run: this.onRedo.bind(this)
        },
        {
          title: `${this.$t(
            `synoptic.${this.isPanelLocked ? "unlock" : "lock"}`
          )} ${this.$t("controls").toLowerCase()}`,
          run: this.onLock.bind(this),
          class: this.isPanelLocked ? "is-active" : "",
          icon: {
            class: "fa fa-lock",
            style: "font-size: 1.1em"
          }
        },
        {
          title: this.$t(
            `synoptic.${
              this.selectToChange ? "disable_select_mode" : "enable_select_mode"
            }`
          ),
          run: this.toggleSelectToChange.bind(this),
          class: this.selectToChange ? "is-active" : "",
          icon: {
            class: "fa fa-hand-pointer-o"
          }
        },
        {
          title: this.$t("new_panel"),
          run: this.onNew.bind(this),
          icon: {
            class: "fa fa-file-o"
          }
        }
      ];
    }
  },
  watch: {
    display: {
      handler(n, o) {
        if (this.ready) {
          if (n && o && n.id && o.id && n.id != o.id) {
            this.ready = false;
            this.$nextTick(() => {
              this.ready = true;
            });
          }
        } else {
          if (this.mode == "editor") {
            let panel =
              (n.panels || []).find((p) => p.template == "SynopticPanel") ||
              null;
            this.$store.dispatch("synoptic/restore", {
              id: this.screenId,
              panel
            });
            this.setupEditor();
          }
          this.ready = true;
        }
      },
      deep: true
    },
    $route(to) {
      if (this.editorEditPath() == to.path) {
        this.setupEditor();
      }
    },
    dashboardDraftPanel(n) {
      // TODO: this is not the proper way since synoptic module should make use of a shared panel instance, instead of a local copy.
      // In future only one single instance of the panel should be manage and this sync function will not be needed anymore
      // Important: This function validates properties not managed by synoptic.
      if (this.mode == "editor" && n && n.name == this.panel.name) {
        let panel = this.$store.getters["synoptic/panel"];
        if (panel) {
          let dirty = false;
          if (n.title != panel.title) {
            panel.title = n.title;
            dirty = true;
          }
          if (JSON.stringify(n.style) != JSON.stringify(panel.style)) {
            panel.style = n.style;
            if ("background-color" in n.style) {
              // backwards compatibility
              if (panel?.options?.canvas?.style) {
                panel.options.canvas.style["background-color"] =
                  n.style["background-color"];
              }
            }
            dirty = true;
          }
          if (JSON.stringify(n.toolbar) != JSON.stringify(panel.toolbar)) {
            panel.toolbar = n.toolbar;
            dirty = true;
          }
          if (dirty) {
            this.$store.commit("synoptic/SET_PANEL", panel);
          }
        }
      }
    },
    isEditing(n, o) {
      if (n) {
        this.$root.$emit("panelFocus");
      } else if (o) {
        this.$store.dispatch("synoptic/clearSelectedControls");
      }
    }
  },
  methods: {
    editorEditPath(screen) {
      let screenId = screen && screen.id > 0 ? screen.id : this.screenId;
      return `/dashboard/screen/edit/${screenId}/${this.panelName}`;
    },
    setupEditor() {
      // if (this.$store.getters["dashboard/editorPanelName"] == this.panelName) {
      if (this.isEditing) {
        this.$store.dispatch("synoptic/setupPanel", {
          id: this.screenId,
          panel: this.panel
        });
        this.$emit("initCustomProperties", {
          panelName: this.panelName,
          propertyEditor: SynopticPropertyEditor
        });
      }
    },
    onNew() {
      let wrapper = document.createElement("div");
      wrapper.innerHTML = this.$t(
        "this_will_reset_all_of_your_unpushed_changes"
      );
      this.$swal({
        title: this.$t("are_you_sure"),
        content: wrapper,
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t("confirm")]
      }).then((option) => {
        if (option) {
          this.$store.dispatch("synoptic/create", "SynopticPanel");
        }
      });
    },
    onLock() {
      this.$store.dispatch("synoptic/toggleLockControls");
    },
    onRedo() {
      this.$root.$emit("redo");
    },
    onUndo() {
      this.$root.$emit("undo");
    },
    onUpdateParams(params) {
      (this?.panelOptions?.controls || [])
        .filter(
          (ctrl) =>
            ctrl.enabled &&
            ctrl.synopticComponent &&
            ctrl.name in (params || {})
        )
        .forEach((ctrl) => {
          var dataId = params[ctrl.name]?.data_id || params[ctrl.name] || "";
          if (dataId != ctrl.data_id) {
            // console.log(dataId);
            this.$set(ctrl, "data_id", dataId);
            if (
              !(this.$store.getters["dashboard/dataList"] || []).some(
                ({ id }) => id == dataId
              )
            ) {
              this.$store.dispatch("dashboard/fetchResources", {
                resource: "data",
                list: [dataId],
                forceUpdate: true
              });
            }
          }
        });
    },
    setupEmbeddedPage() {
      // this method should only be called while in embedded mode (iframe).
      if (window.location == window.parent.location) return;
      window.onmessage = (e) => {
        if (e.data && typeof e.data == "object" && "hi_msg" in (e.data || {})) {
          let params = e?.data?.hi_msg?.action?.options?.params || {};
          this.onUpdateParams(params);
        }
      };
      window.top.postMessage({ hi_msg: "NACK", from: window.name }, "*");
    },
    toggleSelectToChange() {
      this.$store.dispatch("synoptic/toggleSelectToChange");
    }
  },
  mounted() {
    if (this.mode == "editor") {
      this.setupEditor();
    }
    if (this.isEditing) {
      this.$root.$emit("controlSidebar:setContent", SynopticPropertyEditor);
    }
  },
  created() {
    if (this.mode == "editor") return;
    if (window.location !== window.parent.location) {
      this.setupEmbeddedPage();
    }
  }
};
</script>

<style scoped>
.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.box > .box-header {
  padding: 0 5px;
}

.embedded {
  border-top-width: 0;
  margin-top: -20px;
}

.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.box-body-synotic {
  padding: 0;
}

.box-header > .box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
  text-align: right;
  width: 100%;
}

.box-header > span {
  padding: 0 10px 0 2px;
  font-size: 20px;
}

.fa-redo {
  transform: rotateY(180deg);
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}
</style>
<style lang="scss">
.box-tools .btn.btn-box-tool.btn-img {
  width: 20px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 50%;
}

.btn-img.disabled {
  opacity: 0.5;
}

.btn-undo,
.btn-undo:active {
  background-image: url(/static/common/images/undo.png);
}

.btn-redo,
.btn-redo:active {
  background-image: url(/static/common/images/redo.png);
}
</style>
