<template>
  <div class="me" v-if="show">
    <div class="event-title">
      <div>
        <div
          class="pull-left event-title-text clicable no-select"
          v-on:click.stop.prevent="collapsed = !collapsed"
        >
          <i class="fa fa-flash"></i>
          <span class="">{{ $t("synoptic.events.on") }} &nbsp;</span>
          <span class="">{{
            $tc(`synoptic.events.${eventName}`, 2).toLowerCase()
          }}</span>
        </div>
        <div class="pull-right">
          <span class="btn btn-xs">
            <i
              class="fa fa-trash"
              v-on:click.stop.prevent="$emit('delEvent', eventName)"
            ></i>
          </span>
          <span
            class="btn btn-xs"
            v-on:click.stop.prevent="collapsed = !collapsed"
          >
            <i
              class="fa"
              :class="
                collapsed ? 'fa-caret-square-o-down' : 'fa-caret-square-o-up'
              "
            ></i>
          </span>
        </div>
      </div>
      <div class="event-title-text-bottom"></div>
    </div>
    <ControlEventDataListForm
      v-if="requireDataSelection && !collapsed"
      :control="control"
      :eventName="eventName"
    />
    <div class="action-panel" v-if="!collapsed && !requireDataSelection">
      <div
        v-if="control.synopticComponent.on[eventName].actions.length"
        style="margin-bottom:5px;"
      >
        {{ $tc("synoptic.events.execute_action", 2) }}
      </div>
      <ControlEventActionForm
        v-for="(action, iAction) in control.synopticComponent.on[eventName]
          .actions"
        :key="iAction"
        :control="control"
        :eventName="eventName"
        :action="action"
        :index="iAction"
        :open="iAction === newActionIndex"
        @delAction="onDelAction"
        @expand="onExpandAction(iAction, true)"
        @collapse="onExpandAction(iAction, false)"
      />
      <div class="action-selector" v-if="expandedActions.length == 0">
        <ControlActionSelector
          :control="control"
          :eventName="eventName"
          :exclude="eventActions"
          @addAction="onAddAction"
        />
      </div>
    </div>

    <AlertForm v-if="eventName == 'click'" v-model="confirmation" />
  </div>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventDataListForm from "@/components/editor/control-event-data-list-form.vue";
import AlertForm from "@/components/editor/alert-form.vue";

export default {
  name: "ControlEventForm",
  props: {
    control: {
      type: Object,
      required: true
    },
    eventName: {
      type: String,
      required: true
    }
  },
  components: {
    ControlEventActionForm,
    ControlActionSelector,
    ControlEventDataListForm,
    AlertForm
  },
  data() {
    return {
      show: true,
      collapsed: false,
      showProcessArea: false,
      newActionIndex: -1,
      expandedActions: []
    };
  },
  computed: {
    hasSelectedData() {
      return (
        this?.control?.synopticComponent?.on[this.eventName]?.data_list || []
      ).length;
    },
    requireDataSelection() {
      return (
        "data_list" in
        (this?.control?.synopticComponent?.on[this.eventName] || {})
      );
    },
    processArea: {
      get() {
        return {
          id: this.control.synopticComponent.on[this.eventName].process_area_id
        };
      },
      set({ id }) {
        let control = JSON.parse(JSON.stringify(this.control));
        control.synopticComponent.on[this.eventName].process_area_id = id;
        this.$store.dispatch("synoptic/updateControl", {
          id: control.id,
          control: control
        });
      }
    },
    eventActions() {
      let actions = [];
      let control = this.control;
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      if (control) {
        let evt =
          control.synopticComponent.on[this.eventName] ||
          Events[this.eventName];
        actions = evt.actions.map((i) => i.type);
      }
      return actions;
    },
    controlId() {
      return this?.control?.id || "";
    },
    confirmation: {
      set(value) {
        let control = JSON.parse(JSON.stringify(this.control));
        control.synopticComponent.on[this.eventName].confirmation = value;
        this.$store.dispatch("synoptic/updateControl", {
          id: control.id,
          control: control
        });
      },
      get() {
        return (
          this?.control?.synopticComponent?.on[this.eventName]?.confirmation ||
          null
        );
      }
    }
  },
  watch: {
    controlId() {
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    }
  },
  methods: {
    onExpandAction(index, option) {
      if (option) {
        this.expandedActions.push(index);
      } else {
        let ix = this.expandedActions.indexOf(index);
        if (ix >= 0) {
          this.expandedActions.splice(ix, 1);
        }
      }
    },
    onAddAction(action) {
      let control = this.control;
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      let evt =
        control.synopticComponent.on[this.eventName] || Events[this.eventName];
      evt.actions.push(action);
      this.$set(control.synopticComponent.on, this.eventName, evt);
      if (synopticControl) {
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      }
      this.newActionIndex = evt.actions.length - 1;
    },
    onDelAction(index) {
      let control = this.control; // not a synoptic control
      let synopticControls =
        this.$store.getters["synoptic/selectedControls"] || [];
      let synopticControl = synopticControls.length
        ? synopticControls[0]
        : null;
      if (synopticControl) {
        control = synopticControl;
      }
      let evt = control.synopticComponent.on[this.eventName];
      let actions = evt.actions;
      actions.splice(index, 1);
      this.$set(control.synopticComponent.on, this.eventName, evt);
      if (synopticControl) {
        this.$store.commit("synoptic/UPDATE_CONTROL_BY_ID", {
          id: control.id,
          control: control
        });
      }
      this.newActionIndex = -1;
    }
  }
};
</script>

<style scoped>
.me {
  clear: both;
}

.action-panel {
  padding: 5px 10px 5px 5px;
  clear: both;
  background-color: whitesmoke;
}

.action-selector {
  margin-top: 20px;
}

.event-title {
  clear: both;
  font-size: 14pt;
  z-index: 99;
}

.event-title-text {
  color: #627482;
  font-weight: 600;
  letter-spacing: -1px;
}

.event-title-text > i {
  margin: 0 5px;
  font-size: 12pt;
}

.event-title-text > i:hover {
  cursor: pointer;
  opacity: 0.8;
}

.event-title-text-bottom {
  clear: both;
  border-bottom: 1px solid rgb(190, 190, 190);
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
