<template>
  <div class="me">
    <draggable class="list-group" v-model="controls">
      <div v-for="(item, ix) in controls" v-bind:key="ix">
        <div
          class="list-group-item item group-item"
          v-bind:class="isSelected(item) ? 'active' : ''"
          data-testid="control"
        >
          <div
            class="control-icon move handle"
            :style="`background-image:url('${itemIcon(item)}')`"
            :title="itemComponentName(item)"
            v-on:click.prevent.stop="selectControl($event, item)"
          ></div>
          <input
            v-on:click.prevent.stop="selectControl($event, item)"
            v-bind:placeholder="itemTitle(item)"
            :value="item.name"
            :class="inputClass(item)"
            @input="updateName(ix, $event.target.value)"
            @keydown.tab.exact.prevent.stop
            @keydown.ctrl.tab.exact.prevent.stop
            @keydown.shift.tab.exact.prevent.stop
            @keydown.esc.prevent.stop="clearSelection"
            data-testid="name"
          />

          <span class="item-buttons">
            <span
              class="btn btn-xs"
              v-on:click.prevent.stop="duplicateControl(item.id)"
              data-testid="duplicate"
            >
              <i class="fa fa-copy"></i>
            </span>
            <span
              class="btn btn-xs"
              v-on:click.prevent.stop="toggleControl(item.id)"
              data-testid="toggle"
            >
              <i class="fa fa-eye" v-if="item.enabled"></i>
              <i class="fa fa-eye-slash" v-else></i>
            </span>
            <span
              class="btn btn-xs"
              v-on:click.prevent.stop="delControl(item.id)"
              data-testid="delete"
            >
              <i class="fa fa-trash"></i>
            </span>
          </span>
        </div>
      </div>
    </draggable>
  </div>
</template>

<script>
import GalleryItems from "@/assets/dashboard/controls.json";
import { mapActions } from "vuex";
import draggable from "vuedraggable";
export default {
  name: "ControlSelectionPanel",
  components: {
    draggable
  },
  data() {
    return {
      gallery_items: GalleryItems
    };
  },
  computed: {
    controls: {
      get() {
        let lst = [];
        (this.$store.getters["synoptic/controls"] || []).forEach((item) =>
          lst.unshift(item)
        );
        return lst;
      },
      set(value) {
        let lst = [];
        (value || []).forEach((item) => lst.unshift(item));
        this.$store.dispatch("synoptic/setControls", lst);
      }
    }
  },
  methods: {
    inputClass(item) {
      return "data_id" in item && !item.data_id ? "text-danger" : "";
    },
    itemTitle(item) {
      if (item.name) {
        return item.name;
      }
      if (item.title) {
        return item.title; // new items
      }
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (
        (ctrl && ctrl.template.title) || item.synopticComponent.componentName
      );
    },
    itemIcon(item) {
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (ctrl && ctrl.thumbnail) || "";
    },
    itemComponentName(item) {
      // items present on stored json
      let ctrl = this.gallery_items.find(
        (i) =>
          i.template.synopticComponent.componentName ==
          item.synopticComponent.componentName
      );
      return (ctrl && ctrl?.template?.title) || "";
    },
    isSelected(item) {
      return (this.$store.getters["synoptic/selectedControls"] || []).find(
        (i) => i.id == item.id
      )
        ? true
        : false;
    },
    selectControl($event, item) {
      if (!item.name) {
        let el = $event.target;
        let txt = item.title;
        setTimeout(() => {
          el.value = txt;
          el.setSelectionRange(0, txt.length);
        }, 0); //not sure why nexttick did not work here
      }
      this.$store.dispatch("synoptic/selectControl", item.id);
    },
    clearSelection($event) {
      $event.target.blur();
      this.$store.dispatch("synoptic/clearSelection");
    },
    updateName(index, name) {
      let control = JSON.parse(JSON.stringify(this.controls[index]));
      control.name = name;
      this.$store.dispatch("synoptic/updateControl", {
        id: control.id,
        control
      });
    },
    ...mapActions("synoptic", [
      "delControl",
      "toggleControl",
      "duplicateControl"
    ])
  }
};
</script>

<style scoped>
.me {
  max-height: 400px;
  overflow: auto;
}

.group-item {
  padding: 0;
  cursor: pointer;
  min-height: 30px;
}

.list-group-item > .item-buttons {
  position: absolute;
  z-index: 9999;
  right: 0;
}

.btn-group-xs > .btn,
.btn-xs {
  padding: 1px 3px;
}

.list-group-item > .item-buttons > .btn {
  opacity: 0.8;
}

.list-group-item > .item-buttons > .btn:hover {
  opacity: 1;
}

.control-icon {
  position: absolute;
  top: 4px;
  left: 2px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-x: 0px;
  background-position-y: 0px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  z-index: 1;
  cursor: move;
}

.control-icon:hover {
  cursor: move;
}

input {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  border: none !important;
  padding: 0 60px 0 26px;
  color: gray;
  background-color: transparent;
  vertical-align: middle;
}

input:focus {
  border: none !important;
  outline-width: 0 !important;
  background-color: transparent;
}

.active > input {
  color: #ffffff;
}

.active > input::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff;
}

.active > input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff;
}

.active > input::placeholder {
  color: #ffffff;
}

input.text-danger {
  color: #a94442 !important;
}

input.text-danger::placeholder {
  color: #a94442 !important;
}

input.text-danger::-webkit-input-placeholder {
  /* Edge */
  color: #a94442 !important;
}

input.text-danger::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a94442 !important;
}
</style>
