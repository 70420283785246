<template>
  <div class="me">
    <div class="form-group form-group-sm">
      <div class="form-group-label">
        {{ $t("synoptic.events.select_an_action") }}
        <Tooltip
          :title="$t('hints.select_the_event_that_will_trigger_actions')"
        ></Tooltip>
      </div>
      <div class="input-group">
        <div class="input-group-addon ">
          <small> {{ $tc("new") }} {{ $tc("event", 1) }} </small>
        </div>
        <select
          v-model="selected"
          class="form-control select-sm"
          id="event_selection"
          style="font-size:90%;"
        >
          <option value="">{{ $t("synoptic.events.select_an_event") }}</option>
          <option :value="item" v-for="(item, ix) in items" :key="ix">
            {{ $tc(`synoptic.events.${item}`) }}
          </option>
        </select>
      </div>
      <div
        class="text-center"
        style="padding:5px 0;border-bottom:1px solid lightgray;"
      >
        <span
          class="btn btn-xs btn-primary"
          @click.stop.prevent="add"
          :class="!selected ? 'disabled' : ''"
        >
          {{ $t("synoptic.events.add_an_event") }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import ControlsSettings from "@/assets/dashboard/controls_settings.json";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "ControlEventSelector",
  components: {
    Tooltip
  },
  props: {
    control: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selected: ""
    };
  },
  computed: {
    eventList() {
      if (!this.control) return [];
      if ("synopticComponent" in this.control) {
        // synoptic control
        return ControlsSettings[
          this?.control?.synopticComponent?.componentName || ""
        ].events;
      } else {
        //
        // TODO:
        //  It is not a synoptic control (e.g. generic panel toolbar button)
        //  proper validation of control type and its supported events
        //
        return ["click"];
      }
    },
    items() {
      // it might be a regular panel control (not a synoptic one)
      let lst = JSON.parse(JSON.stringify(this.eventList || []));
      let inserted =
        this?.control?.synopticComponent?.on || this?.control?.on || {};
      for (let name in inserted) {
        let ix = lst.findIndex((item) => item == name);
        if (ix >= 0) {
          lst.splice(ix, 1);
        }
      }
      return lst;
    }
  },
  watch: {
    // selected(n) {
    //   if (n) {
    //     this.$emit("addEvent", this.selected);
    //   }
    // },
    items: {
      handler(n) {
        this.selected = "";
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    add() {
      this.$emit("addEvent", this.selected);
    }
  }
};
</script>

<style scoped>
.me {
  margin-bottom: 10px;
  padding: 0 5px;
}
.select-sm {
  font-size: 12pt;
  padding: 0;
}
.form-group-sm {
  margin-bottom: 0;
}
</style>
