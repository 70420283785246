<script>
import SynopticEquipmentDataControl from "@/components/synoptic/synoptic-equipment-data-control.vue";

export default {
  name: "SynopticEquipmentDataControlBase",
  extends: SynopticEquipmentDataControl,
  computed: {
    formatList() {
      return this.$root.config.references.data_value_format_types || [];
    },
    textList() {
      if (!this?.lastData?.text_list?.id) return null;
      return (this.$root.config.references.text_lists || []).find(
        ({ id }) => id == this.lastData.text_list.id || ""
      );
    },
    dataFormat() {
      if (!this.lastData) return null;
      return this.formatList.find(
        ({ id }) => id == this.lastData?.value_format_type?.id || ""
      );
    },
    mode() {
      return this.$store.getters["dashboard/mode"] || "viewer";
    },
    tmp() {
      // temporary actions result (not persistent)
      return this?.control?.synopticComponent?.tmp || null;
    },
    controlStyle() {
      let style = Object.assign({}, this.control.synopticComponent.style, {});
      if (this.tmp && this.tmp.style) {
        Object.assign(style, this.tmp.style);
      }
      return {
        ...style,
        "border-radius": style["border-radius"] ?? "3px",
        transform: `rotate(${parseInt(
          this.control.synopticComponent.rotation || 0
        )}deg)`
      };
    },
    timestamp() {
      return this?.lastData?.current_value?.date_time || "";
    }
  },
  watch: {
    timestamp: {
      handler(n, o) {
        if (n == o) return;
        this.dataChange();
      },
      immediate: true
    },
    lastData: {
      handler(n) {
        if (n) {
          // console.log(n?.current_value?.value);
          this.$forceUpdate();
        }
      },
      deep: true
    }
  },
  methods: {
    equipmentData(id) {
      return (
        (this.$store.getters["dashboard/dataList"] || []).find(
          (i) => i.id == id
        ) || null
      );
    },
    dataChange() {
      let text = [];
      let ts = this?.lastData?.current_value?.date_time || "";
      ts = ts ? this.$dt.format(ts) : "";
      let vlr = this?.lastData?.current_value
        ? this?.lastData?.current_value.value
        : "";
      if (vlr !== "" && !isNaN(parseFloat(vlr)) && this.control.format) {
        if (
          this?.dataFormat?.format_mask == "duration" &&
          this.control.format.indexOf("%") == -1
        ) {
          vlr = moment
            .duration(parseInt(vlr), this.lastData.unity_label)
            .format(this.control.format || "", { trim: false });
        } else {
          vlr = this.$utils.sprintf(this.control.format, vlr);
        }
      }
      vlr = vlr === "" ? this.$t("null") : vlr;
      let value = ts
        ? `[${this.$t("value")}: ${vlr} - ${ts}]`
        : `[${this.$t("value")}: ${vlr}]`;
      let label =
        this?.control?.synopticComponent.hint ||
        this?.lastData?.description ||
        this?.lastData?.name ||
        this?.control?.title ||
        "";
      text.push(`${label} ${value}`);
      if (this.error) {
        text.push(this.$t(this.error));
      }
      if (this.lastData && !this.lastData.enabled) {
        text.push(this.$tc("disabled"));
      }
      this.$emit("dataChange", text.join("\n"));
    }
  }
};
</script>
